import { KTSVG } from "../../helpers";
import { useEffect, useState } from "react";
import { detailsContract, getMeatContract, getParticipant } from "../../../app/request/contract";
import { IDadosGerais, ICarne, IFormContract } from "../../../app/interface/I_Contract";
import { IClient, IParticipant } from "../../../app/interface/I_Client";
import TableSimple from "../../layout/components/tables/TableSimple";
import getHeaderForTypeContract from "./HeaderTableContract";
import { TableOptions } from "../../../app/interface/I_Table";
import { FormEditClient } from "../../../app/pages/client/modal/FormEditClient";
import { ICorretor } from "../../../app/interface/I_Corretor";
import { FormEditCorretor } from "../../../app/pages/corretores/modal/FormEditCorretor";
import { setHistoric } from "../../../app/redux/api/historico/historicSlice";
import { useDispatch } from "react-redux";
import { statusContract } from "../../../app/Constants";
import { DrawerAntecipation } from "../../../app/pages/contrato/memoria_calculo/antecipation/DrawerAntecipation";
import { DrawerQuitacao } from "../../../app/pages/contrato/memoria_calculo/quitacao/DrawerQuitacao";
import { DrawerDistrato } from "../../../app/pages/contrato/memoria_calculo/distrato/DrawerDistrato";
import { formatarPercentage } from "../../helpers/functions/numbers";
import { FormEditContract } from "./modal/contract/FormEditContract";
import ComponentPermission from "../../layout/components/permissions/ComponentPermission";

export const DetailsContract = ({ id }: { id: number }) => {


    const dispatch = useDispatch();

    const [contractDataDetails, setContractDataDetails] = useState<IDadosGerais | null>(null);
    const [dataCorretor, setDataCorretor] = useState<ICorretor>({
        id: '0',
        name: '',
        rg: '',
        cpf: '',
        phone: '',
        email: '',
        creci: '',
        code_bank: '',
        bank: '',
        codigo_operation_bank: '',
        operation_bank: '',
        agency: '',
        account: '',
        account_digit: '',
        pix: '',
    });

    const [contractDataMeat, setContractDataMeat] = useState<ICarne | null>(null);
    const [contractDataParticipant, setContractDataParticipant] = useState<IParticipant | null>(null);

    const [isModalEditContractOpen, setIsModalEditContractOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isModalEditCorretorOpen, setIsModalEditCorretorOpen] = useState(false);
    const [isDrawerAtencipationOpen, setIsDrawerAtencipationOpen] = useState(false);
    const [isDrawerQuitacaoOpen, setIsDrawerQuitacaoOpen] = useState(false);
    const [isDrawerDistratoOpen, setIsDrawerDistratoOpen] = useState(false);
    const [tipoCliente, setTipoCliente] = useState('');
    const [tableData, setTableData] = useState<IClient[]>([]);
    const [isReload, setIsReload] = useState(false);

    const [formDataContract, setFormDataContract] = useState<IFormContract>({
        id: '',
        id_client: '',
        id_product: '',
        id_unit: '',
        id_corretor: '',
        name: '',
        fullname_contract: '',
        name_product: '',
        name_unit: '',
        name_corretor: '',
        description: '',
        status: '',
        value: '',
        rate: '',
        readjustment_index: '',
        readjustment_check: '',
        readjustment_application: '',
        contract_date: '',
        mora: '',
        multa: ''
    });

    const [formValues, setFormValues] = useState<IClient>({
        id: '0',
        name: '',
        rg: '',
        cpf: '',
        telefone: '',
        email: '',
        id_client_relationship: 0,
        address: '',
        number: '',
        zip_code: '',
        neighborhood: '',
        city: '',
        state: '',
        complement: '',
    });

    const [clientOriginal, setClientOriginal] = useState<IClient>(formValues);


    /** aciona modald e editar o cliente */
    const handleEdit = () => {
        setTipoCliente('client');
        setFormValues(prevState => ({
            ...prevState,
            ...clientOriginal
        }));

        setIsModalEditOpen(true);
    };

    const handleEditParticipant = (participantData: any) => {
        setTipoCliente('participant');

        setFormValues({
            ...clientOriginal, // mantém o cliente como base
            ...participantData // sobrescreve os campos com dados do participante
        });

        setIsModalEditOpen(true);
    };

    // Propopriedade para montar header e  lass e endopoint dinamico
    const optionsClient: TableOptions = {
        header: getHeaderForTypeContract('client', handleEditParticipant),
        iInterface: 'participante',
        endPoint: `getParticipant/${id}/`, // recuperar participante pelo id do contrato
        methodEndPoint: 'post',
        classTable: 'table align-middle table-row-dashed fs-6 gy-4 mb-0',
        classHeadTr: 'border-bottom border-gray-200 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0',
        classHeadTd: 'min-w-150px',
        classTbody: 'fw-semibold text-gray-800',
        classtbodyTr: '',
        classTbodyTd: '',
        isHidePaginate: true,
        classTbodyTdDiv: 'fw-normal text-gray-600'
    };


    const optionsCarne: TableOptions = {
        header: getHeaderForTypeContract('meat'),
        iInterface: 'carne',
        endPoint: 'getMeatContract',
        methodEndPoint: 'get',
        classTable: 'table align-middle table-row-dashed fs-6 gy-4 mb-0',
        classHeadTr: 'border-bottom border-gray-200 text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0',
        classHeadTd: 'min-w-150px',
        classTbody: 'fw-semibold text-gray-800',
        classtbodyTr: '',
        classTbodyTd: '',
        classTbodyTdDiv: 'fw-normal text-gray-600'
    };

    /** ao clicar no botão editar */
    const handleEditContract = () => {

        setIsModalEditContractOpen(true);
    };

    /** ao clicar no botão editar */
    const modalCloseContract = () => {

        setIsModalEditContractOpen(false);
    };

    useEffect(() => {
        async function fetchData() {
            // contractDataDetails?.name_product
            // detalhes do contrato
            try {
                const responseDetails = await detailsContract(id || 0);
                setContractDataDetails(responseDetails.data);

                setFormDataContract({
                    id: responseDetails?.data.id || '0',
                    id_client: responseDetails?.data.id_client || '0',
                    id_product: responseDetails?.data.id_product || '0',
                    id_unit: responseDetails?.data.id_unit || '0',
                    id_corretor: responseDetails?.data.id_corretor || '',
                    name: responseDetails?.data.name_contract || '',
                    fullname_contract: responseDetails?.data.fullname_contract || '',
                    name_product: responseDetails?.data.name_product || '',
                    name_unit: responseDetails?.data.name_unit || '',
                    name_corretor: responseDetails?.data.name_corretor || '',
                    description: responseDetails?.data.description || '',
                    status: responseDetails?.data.status || '1',
                    rate: responseDetails?.data.rate || '',
                    readjustment_index: responseDetails?.data.readjustment_index || '',
                    readjustment_check: responseDetails?.data.readjustment_check || '',
                    readjustment_application: responseDetails?.data.readjustment_application || '',
                    contract_date: responseDetails?.data.contract_date || '',
                    mora: responseDetails?.data.mora || '',
                    multa: responseDetails?.data.multa || '',
                });

                const dataCliente = {
                    id: responseDetails?.data.id_client || '0',
                    name: responseDetails?.data.name_client || '',
                    rg: responseDetails?.data.rg || '',
                    cpf: responseDetails?.data.cpf || '',
                    telefone: responseDetails?.data.telefone || '',
                    email: responseDetails?.data.email || '',
                    address: responseDetails?.data.address || '',
                    number: responseDetails?.data.number || '',
                    zip_code: responseDetails?.data.zip_code || '',
                    neighborhood: responseDetails?.data.neighborhood || '',
                    city: responseDetails?.data.city || '',
                    state: responseDetails?.data.state || '',
                    complement: responseDetails?.data.complement || '',
                }

                setFormValues(prevState => ({
                    ...prevState,
                    ...dataCliente

                }));

                setClientOriginal(prevState => ({
                    ...prevState,
                    ...dataCliente

                }));

                setDataCorretor(prevState => ({
                    ...prevState,
                    id: responseDetails?.data.id_corretor || '0',
                    name: responseDetails?.data.name_corretor || '',
                    cpf: responseDetails?.data.cpf_corretor || '',
                    phone: responseDetails?.data.telefone_corretor || '',
                    email: responseDetails?.data.email_corretor || '',
                    creci: responseDetails?.data.creci || '',
                    code_bank: responseDetails?.data.code_bank || '',
                    bank: responseDetails?.data.bank || '',
                    codigo_operation_bank: responseDetails?.data.codigo_operation_bank || '',
                    operation_bank: responseDetails?.data.operation_bank || '',
                    agency: responseDetails?.data.agency || '',
                    account: responseDetails?.data.account || '',
                    account_digit: responseDetails?.data.account_digit || '',
                    pix: responseDetails?.data.pix || '',
                }));

                if (responseDetails?.data.id_client) {
                    dispatch(setHistoric({ 'id_contract': id, 'id_client': responseDetails.data.id_client }));
                }


            } catch (error) {
                // Trate o erro aqui
                console.log("Ocorreu um erro ao buscar os dados do responseDetails:", error);
            }
        }
        fetchData();
    }, [id]);

    const reloadTable = () => {
        setIsReload(true);
    }


    let situation = '';
    if (!!contractDataDetails?.cancellation_date) {
        situation = `${contractDataDetails?.name_contract || ''} - Com distrato`;
    } else if (!!contractDataDetails?.discharge_date) {
        situation = `${contractDataDetails?.name_contract || ''} - Quitado em ${contractDataDetails?.discharge_date}`
    } else {
        situation = contractDataDetails?.name_contract || '';
    }

    /** modal editar */
    const closeModalEdit = () => {

        setFormValues(prevState => ({
            ...prevState,
            ...clientOriginal

        }));

        setIsReload(false);

        setIsModalEditOpen(false);
    };

    /** aciona modal editar corretor */
    const handleEditCorretor = () => {
        setIsModalEditCorretorOpen(true);
    };

    const closeModalCorretor = () => {
        setIsModalEditCorretorOpen(false);
    };

    /** funcao que decide resposta ao editar cliente */
    const handlerCallbackFormSubmit = (formData: IClient) => {

        if (formData.hasOwnProperty('id') && formData.id !== "0" && formData.id !== null && formData.id !== "" && formData.name !== "") {
            // const updatedClient = {
            //     ...formData
            // };            

            setFormValues(prevState => ({
                ...prevState,
                ...formData
            }));

            if (tipoCliente === 'client') {
                setClientOriginal(prevState => ({
                    ...prevState,
                    ...formData
                }));
            }

            if (tipoCliente === 'participant') {
                setTableData([formData]);

                reloadTable();
            }

            // const updatedTableData = [updatedClient];
            setIsModalEditOpen(false);
        }
    }

    /** funcao que decide resposta ao editar corretor */
    const handlerCallbackFormSubmitCorretor = (formData: ICorretor) => {

        if (formData.hasOwnProperty('id') && formData.id !== "0" && formData.id !== null && formData.id !== "" && formData.name !== "") {
            setDataCorretor(prevState => ({
                ...prevState,
                ...formData
            }));

            setIsModalEditCorretorOpen(false);
        }
    }

    const status_contract = statusContract.find(item => item.value === contractDataDetails?.status)?.label || 'Ativo';

    /** drawer antecipacao de parcela */
    const closeDrawerAtencipation = () => {
        setIsDrawerAtencipationOpen(false);
    };

    const closeDrawerQuitacao = () => {
        setIsDrawerQuitacaoOpen(false);
    };

    const closeDrawerDistrato = () => {
        setIsDrawerDistratoOpen(false);
    };


    const antecipationParcel = () => {
        setIsDrawerAtencipationOpen(true);
    }

    const quitacao = () => {
        setIsDrawerQuitacaoOpen(true);
    }

    const distrato = () => {
        setIsDrawerDistratoOpen(true);
    }

    useEffect(() => {
        if (isReload) {
            reloadTable();
            setIsReload(false);
        }
    }, []);


    return (
        <div className="card card-flush pt-3 mb-5 mb-xl-10">
            <div className="card-header  position-relative">

                <div className="card-title">
                    <h2 className="fw-bold">Detalhe do Contrato</h2>
                </div>
                <div className="mt-7 d-flex align-items-center gap-3 gap-lg-2">
                    <span className="badge fs-7 badge-light-success me-2">Contrato {status_contract}</span>
                    <ComponentPermission>
                        <ComponentPermission.Edit>
                            <button

                                className='btn btn-sm me-2 btn-active-light-info btn-custom badge fs-7 badge-light-info'
                                onClick={() => handleEditContract()}
                            >
                                Editar contrato
                            </button>
                        </ComponentPermission.Edit>
                    </ComponentPermission>

                    <a
                        href='#'
                        className='btn btn-sm btn-active-light-primary btn-custom badge fs-7 badge-light-primary'
                        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                        data-kt-menu-attach='parent'
                        data-kt-menu-placement='bottom-end'
                    >
                        Simulação
                    </a>

                    {/* begin::Menu */}
                    <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-200px'
                        data-kt-menu='true'
                    >

                        {/* begin::Menu item */}
                        <div className='menu-item px-3 my-0'>
                            <a
                                type="nutton"
                                className='menu-link '
                                onClick={() => antecipationParcel()}
                            >
                                <span className='menu-title'>Antecipação de parcelas</span>
                            </a>
                        </div>
                        {/* end::Menu item */}

                        {/* begin::Menu item */}
                        <div className='menu-item px-3 my-0'>
                            <a
                                type="nutton"
                                className='menu-link '
                                onClick={() => quitacao()}
                            >
                                <span className='menu-title'>Quitação</span>
                            </a>
                        </div>
                        {/* end::Menu item */}

                        {/* begin::Menu item */}
                        <div className='menu-item px-3 my-0'>
                            <a
                                type="nutton"
                                className='menu-link '
                                onClick={() => distrato()}
                            >
                                <span className='menu-title'>Distrato</span>
                            </a>
                        </div>
                        {/* end::Menu item */}

                    </div>
                    {/* <button onClick={() => antecipationParcel()} className="btn btn-sm  btn-primary">Antecipação de parcelas</button> */}
                </div>
            </div>
            <div className="card-body pt-3">
                <div className="mb-10">
                    <h5 className="mb-4 d-flex bg-light-secondary p-3 rounded flex-stack fs-4 py-3">Dados gerais:</h5>

                    <div className="d-flex flex-wrap py-5">
                        <div className="flex-equal me-5">
                            <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0">
                                <tbody>
                                    <tr>
                                        <td className="text-gray-400 min-w-175px w-175px">Empreendimento:</td>
                                        <td className="text-gray-800 min-w-200px">
                                            {contractDataDetails?.name_product || '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Unidade:</td>
                                        <td className="text-gray-800">
                                            {contractDataDetails?.name_unit || '...'}
                                        </td>
                                    </tr>

                                    {/* <tr>
                                        <td className="text-gray-400">Situação:</td>
                                        <td className="text-gray-800">
                                            {contractDataDetails?.situation || '...'}
                                        </td>
                                    </tr> */}

                                    <tr>
                                        <td className="text-gray-400">Contrato:</td>
                                        <td className="text-gray-800">
                                            {situation}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Previsão de termino:</td>
                                        <td className="text-gray-800">
                                            {!!contractDataDetails?.contract_expiration ? `Em ${contractDataDetails?.contract_expiration}` : '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Data do Contrato:</td>
                                        <td className="text-gray-800">
                                            {!!contractDataDetails?.contract_date ? `Em ${contractDataDetails?.contract_date?.split('-').reverse().join('/')}` : '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Valor total:</td>
                                        <td className="text-gray-800">
                                            {contractDataDetails?.value || '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Índice de correção monetária:</td>
                                        <td className="text-gray-800">
                                            {contractDataDetails?.readjustment_index || '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Forma de apuração do reajuste:</td>
                                        <td className="text-gray-800">
                                            {contractDataDetails?.readjustment_check?.toUpperCase() || '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Forma de aplicação do reajuste:</td>
                                        <td className="text-gray-800">
                                            {contractDataDetails?.readjustment_application?.toUpperCase() || '...'}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Multa por atraso:</td>
                                        <td className="text-gray-800">

                                            {`${contractDataDetails?.multa} %`}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Juros Padrão Contrato :</td>
                                        <td className="text-gray-800">
                                            {`${contractDataDetails?.rate} %`}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Juros Mora:</td>
                                        <td className="text-gray-800">
                                            {`${contractDataDetails?.mora} %`}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="text-gray-400">Identificador externo:</td>
                                        <td className="text-gray-800">
                                            {contractDataDetails?.id_proposal_external}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-gray-400">Descrição:</td>
                                        <td className="text-gray-800">
                                            {contractDataDetails?.description || '...'}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="m-0 col-md-4 me-4">
                            <div className="d-print-none row border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten">
                                <div className="mb-4">
                                    <span className="badge badge-light-success me-2">Principal</span>
                                </div>

                                <h6 className="mb-4 fw-bolder text-gray-600">Dados do comprador</h6>

                                <div className="mb-4">
                                    <div className="fw-semibold text-gray-600 fs-7">Nome:</div>

                                    <a type="button" onClick={() => handleEdit()} className="">
                                        <div className="fw-bold text-gray-800 text-hover-primary fs-6">{clientOriginal?.name || '...'} <KTSVG className="ms-2" path="/media/icons/duotune/general/gen055.svg" /></div>

                                    </a>
                                </div>

                                <div className="mb-4">
                                    <div className="fw-semibold text-gray-600 fs-7">Email:</div>

                                    <div className="fw-bold text-gray-800 fs-6">{clientOriginal?.email || '...'}</div>
                                </div>

                                <div className="mb-4">
                                    <div className="fw-semibold text-gray-600 fs-7">Telefone:</div>

                                    <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center">{clientOriginal?.telefone || '...'}</div>
                                </div>

                                <div className="mb-6">
                                    <div className="fw-semibold text-gray-600 fs-7">Cpf:</div>

                                    <div className="fw-bold fs-6 text-gray-800">{clientOriginal?.cpf || '...'}</div>
                                </div>
                            </div>
                        </div>

                        <div className="row col-md-12">
                            <div className="col-sm-3">
                                <div className="fw-semibold fs-7 text-gray-600 mb-1">Corretor:</div>
                                {/*  */}
                                {
                                    dataCorretor?.name ? (
                                        <a
                                            type="button"
                                            onClick={() => handleEditCorretor()}
                                            className="">
                                            <div className="fw-bold text-gray-800 text-hover-primary fs-6 me-2">
                                                {dataCorretor?.name}
                                                <KTSVG className="ms-2" path="/media/icons/duotune/general/gen055.svg" />
                                            </div>
                                        </a>
                                    ) :
                                        (<>...</>)
                                }

                            </div>
                            <div className="col-sm-3">
                                <div className="fw-semibold fs-7 text-gray-600 mb-1">Email:</div>

                                <div className="fw-bold fs-6 text-gray-800">{dataCorretor?.email || '...'}</div>
                            </div>
                            <div className="col-sm-3">
                                <div className="fw-semibold fs-7 text-gray-600 mb-1">Cpf:</div>

                                <div className="fw-bold fs-6 text-gray-800">{dataCorretor?.cpf || '...'}</div>

                            </div>

                        </div>

                    </div>
                </div>

                {/* Participantes */}
                <div className="mb-0">
                    <h5 className="mb-4 d-flex bg-light-secondary p-3 rounded flex-stack fs-4 py-3">Participantes:</h5>
                    <TableSimple
                        options={optionsClient}
                        tableData={tableData}
                    // reloadTable={reloadTable}
                    />
                </div>

                {/* Carne */}
                {/* <div className="mb-0 mt-5">
                    <div className="d-flex bg-light-secondary p-3 rounded flex-stack fs-4 py-3">
                        <div className="fw-bold">
                            Carnê
                        </div>
                        
                        <div className="badge badge-light-info d-inline">Adicionar carnê</div>
                        
                    </div>
                    <TableSimple options={optionsCarne} />
                </div> */}

            </div>

            {isModalEditContractOpen &&
                <FormEditContract
                    formValues={formDataContract}
                    closeModal={modalCloseContract}
                    onFormSubmit={handlerCallbackFormSubmit}
                />
            }

            {isModalEditOpen &&
                <FormEditClient
                    formValues={formValues}
                    closeModal={closeModalEdit}
                    onFormSubmit={handlerCallbackFormSubmit}
                />
            }

            {isModalEditCorretorOpen &&
                <FormEditCorretor
                    formValues={dataCorretor}
                    closeModal={closeModalCorretor}
                    onFormSubmit={handlerCallbackFormSubmitCorretor}
                />
            }

            {isDrawerAtencipationOpen &&
                <DrawerAntecipation
                    formValues={{ id_contract: id, name_contract: contractDataDetails?.fullname_contract || '' }}
                    closeDrawer={closeDrawerAtencipation}
                // onFormSubmit={handlerCallbackFormSubmitCorretor}
                />
            }

            {isDrawerQuitacaoOpen &&
                <DrawerQuitacao
                    formValues={{ id_contract: id, name_contract: contractDataDetails?.fullname_contract || '' }}
                    closeDrawer={closeDrawerQuitacao}
                // onFormSubmit={handlerCallbackFormSubmitCorretor}
                />
            }

            {isDrawerDistratoOpen &&
                <DrawerDistrato
                    formValues={
                        {
                            id_contract: id,
                            name_contract: contractDataDetails?.fullname_contract || ''
                        }
                    }
                    closeDrawer={closeDrawerDistrato}
                // onFormSubmit={handlerCallbackFormSubmitCorretor}
                />
            }

        </div>

    );
}

