/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import TableContract from './TableContract'
import getHeaderForTypeContract from "../../../_metronic/partials/contract/HeaderTableContract";
import { TableOptions } from "../../../app/interface/I_Table";
import TableAdvanced from '../../../_metronic/layout/components/tables/TableAdvanced'
import { IFormContract } from '../../interface/I_Contract'
import { FormInsertContract } from '../../../_metronic/partials/contract/modal/contract/FormInsertContract'
import { FormEditContract } from '../../../_metronic/partials/contract/modal/contract/FormEditContract'
import { optionsActionInMassaStatus } from '../../globals/OptionsGlobals'
import { useSelector } from 'react-redux'
import { exportExcel } from '../../request/contract'
import Swal from 'sweetalert2'
import * as authHelper from './../../modules/auth/core/AuthHelpers'
import { useTableFilters } from '../../../_metronic/layout/useTableFilters'
const uuid = authHelper.getAuthUui() || '';
const lastUuid = authHelper.getAuthLastUui() || '';

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: `/dashboard/access/${uuid || lastUuid}`,
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});



const ContratoPage: FC = () => {

    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [filterForm, setFilterForm] = useState({ id_product: '' });
    const [isModalInsertOpen, setIsModalInsertOpen] = useState(false);
    const [tableData, setTableData] = useState<IFormContract[]>([]);
    const [isReload, setIsReload] = useState(false);

    const [formValues, setFormValues] = useState<IFormContract>({
        id: '0',
        id_client: '0',
        id_product: '0',
        id_unit: '0',
        id_corretor: '',
        name: '',
        name_product: '',
        name_unit: '',
        name_corretor: '',
        description: '',
        status: '',
        value: '...',
        contract_expiration: '...',
        next_payment: '...',
        rate: '',
        readjustment_index: '',
        readjustment_check: '',
        readjustment_application: '',
        contract_date: '',
        mora: '',
        multa: ''
    });

    /** ao clicar no botão inserir */
    const handleInsert = () => {
        setIsModalInsertOpen(true);
    };

    /** ao clicar no botão editar */
    const handleEdit = (row: any) => {

        setFormValues({
            id: row.id || '0',
            id_client: row.id_client || '0',
            id_product: row.id_product || '0',
            id_unit: row.id_unit || '0',
            id_corretor: row.id_corretor || '',
            name: row.name[1] || '',
            name_product: row.name_product || '',
            name_unit: row.name_unit || '',
            name_corretor: row.name_corretor || '',
            description: row.description || '',
            status: row.status_contract || '',
            value: '',
            contract_expiration: '',
            next_payment: '',
            rate: row.rate || '',
            readjustment_index: row.readjustment_index || '',
            readjustment_check: row.readjustment_check || '',
            readjustment_application: row.readjustment_application || '',
            contract_date: row.contract_date || '',
            mora: row.mora || '',
            multa: row.multa || '',
        });

        setIsModalEditOpen(true);
    };

    /** modal inserir */
    const closeModalInsert = () => {
        setIsModalInsertOpen(false);
    };

    /** modal editar */
    const closeModalEdit = () => {
        setIsModalEditOpen(false);
    };

    const reloadTable = () => {
        setIsReload(true);
    }

    const handlerCallbackFormSubmit = (formData: IFormContract) => {

        if (formData.hasOwnProperty('id') && !!formData.id && !!formData.name) {
            const updatedContract = {
                ...formData,
                value: '...',
                status: 'Ativo',
                next_payment: '...',
                contract_expiration: '...',
            };

            const updatedTableData = [updatedContract];

            setTableData(updatedTableData);
            reloadTable();

            setIsModalInsertOpen(false);
            setIsModalEditOpen(false);
        }
    }

    useEffect(() => {
        if (isReload) {
            reloadTable();
            setIsReload(false);
        }
    }, [isReload]);

    const { tableFilters, prepareFiltersForAPI } = useTableFilters();
    const apiFilters = (() => {
        let result = { id_product: '' };

        if (tableFilters?.navbar_id_product) {
            result.id_product = tableFilters.navbar_id_product;
        } else if (Object.hasOwn(tableFilters, "navbar_id_product") && !!tableFilters.navbar_id_product) {
            result.id_product = tableFilters.navbar_id_product;
        }

        return result;
    })();

    let downloadInProgress = false;

    const startProcess = async () => {

        downloadInProgress = true;
        const swalInstance = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            allowOutsideClick: false,
            showClass: { backdrop: 'swal2-noanimation' },
            hideClass: { backdrop: 'swal2-noanimation' },
            showCloseButton: true,
            didOpen(popup) {
                setTimeout(changeStyleToast, 10);
            },
        });

        swalInstance.fire({
            progressSteps: ['1', '2', '3', '4'],
            currentProgressStep: 0,
            title: 'Iniciando o dowload...',
            text: `Progresso atual (0%) concluído`,
            didClose() {
                handleCloseToast(); // Chama a função que exibe o Toast
            },
        });

        try {
            const response = await exportExcel(apiFilters, 'exportExcelContract');

            if (response.data && response.data.init === true) {
                swalInstance.update({
                    progressSteps: ['1', '2', '3', '4'],
                    currentProgressStep: 0,
                    title: 'Consultando dados...',
                    text: `Progresso atual (0%) concluído`,
                })

                monitorProgress(swalInstance, 1);
            } else {
                swalInstance.fire({
                    icon: "info",
                    title: response.data?.message || 'Falha ao iniciar o download.',
                });
            }

        } catch (error) {
            console.error('Erro ao iniciar o processo:', error);
        }

    }

    const monitorProgress = async (swalInstance: any, currentPosition: number) => {

        const interval = setInterval(async () => {
            try {
                // Obtém o progresso do backend com a posição atual
                const response = await exportExcel({ step: currentPosition }, 'getProgressExcelContract');

                const { progressStep, download, name_excel } = response.data;
                const { progress, message, step } = progressStep;

                // Verifica se o progresso retornado é para a posição correta
                if (step === currentPosition) {
                    swalInstance.update({
                        progressSteps: [1, 2, 3, 4],
                        currentProgressStep: step - 1, // SweetAlert usa index baseado em 0
                        title: message,
                        text: `Progresso atual (${progress}%) concluído`,
                        // didClose() {
                        //     closeExportReportContract(interval, swalInstance, progressStep)
                        // },
                    });

                    // Avança para a próxima posição
                    currentPosition++;

                    // Se o progresso for 100%, finaliza o processo e faz o download
                    if (progress === 100) {
                        downloadInProgress = false;
                        clearInterval(interval);

                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = `data:application/octet-stream;base64,${download}`;
                        a.download = name_excel;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);

                        setTimeout(() => {
                            swalInstance.update({
                                progressSteps: [1, 2, 3, 4],
                                currentProgressStep: step - 1,
                                title: 'Download concluído',
                                text: `Progresso (${progress}% concluído)`,
                            });

                            setTimeout(() => {
                                swalInstance.close();
                            }, 1000);

                        }, 2000);
                    }
                } else {
                    // Não avança, continua esperando pela posição atual
                    // console.log(`Aguardando a posição ${currentPosition} no backend...`);
                }

            } catch (error) {
                downloadInProgress = false;
                console.error('Erro ao obter o progresso:', error);
                clearInterval(interval);

                swalInstance.update({
                    title: 'Houve um erro ao tentar fazer seu download!',
                    text: `Contate o administrador.`,
                });

                const progressActiveElement = document.querySelector('.swal2-active-progress-step');
                if (progressActiveElement instanceof HTMLElement) {
                    progressActiveElement.style.backgroundColor = '#cf0f21';
                    progressActiveElement.textContent = 'x';
                }

                setTimeout(() => {
                    swalInstance.close();
                }, 3000);
            }
        }, 2000);
    };

    function changeStyleToast() {
        // const toastElement = document.querySelector('.swal2-toast');
        // if (toastElement instanceof HTMLElement) {
        //     toastElement.style.display = 'grid';
        //     toastElement.style.padding = '3px';
        //     toastElement.style.width = 'auto';
        // }

        // Alterar o estilo do botao de fechar
        const buttonCloseElement = document.querySelector('.swal2-close');
        if (buttonCloseElement instanceof HTMLElement) {
            buttonCloseElement.style.display = 'flex';
            buttonCloseElement.style.position = 'absolute';
            buttonCloseElement.style.top = '0';
        }

        // Alterar o estilo da classe swal2-progress-steps
        const progressSteps = document.querySelector('.swal2-progress-steps');
        if (progressSteps instanceof HTMLElement) {
            progressSteps.style.marginBottom = '3px';
            progressSteps.style.display = 'flex';
            progressSteps.style.fontSize = 'xx-small';
        }

        // Alterar o estilo do id swal2-title
        const titleElement = document.getElementById('swal2-title');
        if (titleElement instanceof HTMLElement) {
            titleElement.style.display = 'block';
            titleElement.style.marginBottom = '-0.02rem';
        }

        // Alterar o estilo do id swal2-html-container
        const htmlContainer = document.getElementById('swal2-html-container');
        if (htmlContainer instanceof HTMLElement) {
            htmlContainer.style.display = 'block';
            htmlContainer.style.fontSize = 'smaller';
        }

    }

    const handleCloseToast = () => {
        if (downloadInProgress) {
            Toast.fire({
                icon: 'success',
                title: 'Seu download continuará em background!',
            });

            setTimeout(() => {
                Toast.close();
            }, 2000);
        }
    };

    const closeExportReportContract = async (interval: any, swalInstance: any, progressStep: any) => {
        const { progress, message, step } = progressStep;

        const response = await exportExcel({}, 'cancelExcelContract');
        clearInterval(interval);

        swalInstance.update({
            progressSteps: [1, 2, 3, 4],
            currentProgressStep: step - 1,
            title: 'Parando download',
            text: `Progresso do processo (${progress}%)`,
        });

        setTimeout(() => {
            swalInstance.fire({
                icon: 'success',
                position: 'top',
                title: 'Download cancelado',
            });
            setTimeout(() => {
                swalInstance.close();
            }, 1000);
        }, 2000);

    }

    const optionsInstallment: TableOptions = {
        // id:id,
        header: getHeaderForTypeContract('contract', handleEdit),
        iInterface: 'contract',
        endPoint: `tableContract`,
        methodEndPoint: 'post',
        title: 'Lista de Contratos',
        classHeadTd: 'min-w-150px',
        classTbody: 'fw-semibold text-gray-800',
        classtbodyTr: '',
        classTbodyTd: '',
        isHidePaginate: false,
        funDeleteInMass: 'deleteContractInMassa',
        funChangeInMass: 'changeContractInMassa',
        textDelete: 'Contratos',
        download: startProcess,
        verifyPermission: 'contract'
    };

    return (

        <div className="app-container  container-xxl ">
            <div className="d-flex flex-column flex-lg-row ms-n11">
                {/* <TableContract api={`tableContract`} type={`contrato`}/> */}
                {/* <TableSimple options={optionsContract} /> */}
                <TableAdvanced
                    options={optionsInstallment}
                    optionsActionInMassa={optionsActionInMassaStatus}
                    tableData={tableData}
                    handleInsert={handleInsert}
                    reloadTable={reloadTable}
                />

                {isModalEditOpen &&
                    <FormEditContract
                        formValues={formValues}
                        closeModal={closeModalEdit}
                        onFormSubmit={handlerCallbackFormSubmit}
                    />
                }

                {isModalInsertOpen &&
                    <FormInsertContract
                        closeModal={closeModalInsert}
                        onFormSubmit={handlerCallbackFormSubmit}
                    // handlerSaveForm={confirmSaveForm} 
                    // formValues={formValuesContract}
                    // handleChange={handleChange}
                    />
                }

            </div>
        </div>
    );
}

const ListContractWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Contratos</PageTitle>
            {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
            <ContratoPage />
        </>
    )
}

export { ListContractWrapper }
