/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import getHeaderForTypeContract from "../../../_metronic/partials/contract/HeaderTableContract";
import { TableOptions } from "../../interface/I_Table";
import TableAdvanced from '../../../_metronic/layout/components/tables/TableAdvanced'
import { IFormContract } from '../../interface/I_Contract'
import { FormInsertContract } from '../../../_metronic/partials/contract/modal/contract/FormInsertContract'
import { FormEditContract } from '../../../_metronic/partials/contract/modal/contract/FormEditContract'
import { IClient } from '../../interface/I_Client';
import { FormInsertClient } from './modal/FormInsertClient';
import { FormEditClient } from './modal/FormEditClient';
import * as authHelper from './../../modules/auth/core/AuthHelpers'
const uuid = authHelper.getAuthUui() || '';
const lastUuid = authHelper.getAuthLastUui() || '';

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: `/dashboard/access/${uuid || lastUuid}`,
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const ClientPage: FC = () => {

    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isModalInsertOpen, setIsModalInsertOpen] = useState(false);
    const [tableData, setTableData] = useState<IClient[]>([]);
    const [isReload, setIsReload] = useState(false);

    const [formValues, setFormValues] = useState<IClient>({
        id: '0',
        name: '',
        rg: '',
        cpf: '',
        telefone: '',
        email: '',
        id_client_relationship: 0,
        address: '',
        number: '',
        zip_code: '',
        neighborhood: '',
        city: '',
        state: '',
        complement: '',
    });

    /** ao clicar no botão inserir */
    const handleInsert = () => {
        setIsModalInsertOpen(true);
    };

    /** ao clicar no botão editar */
    const handleEdit = (row: any) => {

        setFormValues({
            id: row.id || '0',
            name: row.name || '',
            rg: row.rg || '',
            cpf: row.cpf || '',
            telefone: row.telefone || '',
            email: row.email || '',
            id_client_relationship: row.id_client_relationship || 0,
            address: row.address || '',
            number: row.number || '',
            zip_code: row.zip_code || '',
            neighborhood: row.neighborhood || '',
            city: row.city || '',
            state: row.state || '',
            complement: row.complement || '',
        });

        setIsModalEditOpen(true);
    };

    /** modal inserir */
    const closeModalInsert = () => {
        setIsModalInsertOpen(false);
    };

    /** modal editar */
    const closeModalEdit = () => {
        setIsModalEditOpen(false);
    };

    const handlerCallbackFormSubmit = (formData: IClient) => {

        if (formData.hasOwnProperty('id') && formData.id !== "0" && formData.id !== null && formData.id !== "" && formData.name !== "") {
            const updatedClient = {
                ...formData
            };

            const updatedTableData = [updatedClient];

            setTableData(updatedTableData);

            reloadTable();

            setIsModalInsertOpen(false);
            setIsModalEditOpen(false);
        }
    }

    const reloadTable = () => {
        setIsReload(true);
    }

    useEffect(() => {
        if (isReload) {
            reloadTable();
            setIsReload(false);
        }
    }, [isReload]);

    const optionsInstallment: TableOptions = {
        // id:id,
        header: getHeaderForTypeContract('client', handleEdit),
        iInterface: 'client',
        endPoint: `tableClient`,
        methodEndPoint: 'post',
        title: 'Lista de Cliente',
        classHeadTd: 'min-w-150px',
        classTbody: 'fw-semibold text-gray-800',
        classtbodyTr: '',
        classTbodyTd: '',
        isHidePaginate: true,
        funDeleteInMass: 'deleteClientInMassa',
        // funChangeInMass: 'changeContractInMassa',
        textDelete: 'Clientes',
        // verifyPermission: 'client'
    };

    return (

        <div className="app-container  container-xxl ">
            <div className="d-flex flex-column flex-lg-row ms-n11">
                {/* <TableContract api={`tableContract`} type={`contrato`}/> */}
                {/* <TableSimple options={optionsContract} /> */}
                <TableAdvanced
                    options={optionsInstallment}
                    // optionsActionInMassa={optionsActionInMassa}
                    tableData={tableData}
                    handleInsert={handleInsert}
                    reloadTable={reloadTable}
                />

                {isModalEditOpen &&
                    <FormEditClient
                        formValues={formValues}
                        closeModal={closeModalEdit}
                        onFormSubmit={handlerCallbackFormSubmit}
                    />
                }

                {isModalInsertOpen &&
                    <FormInsertClient
                        closeModal={closeModalInsert}
                        onFormSubmit={handlerCallbackFormSubmit}
                    // handlerSaveForm={confirmSaveForm} 
                    // formValues={formValuesContract}
                    // handleChange={handleChange}
                    />
                }

            </div>
        </div>
    );
}

const ListClientWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Clientes</PageTitle>
            {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
            <ClientPage />
        </>
    )
}

export { ListClientWrapper }
