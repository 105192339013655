
import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import { FormClientProps } from "../../../interface/I_Client";
import { useAuth } from "../../../modules/auth";
import * as Yup from 'yup'
import { SchemaClient } from "../SchemaClient";
import InputMask from 'react-input-mask';
import { useMemo } from 'react';
import { editClientAPI } from "../../../request/client";
import Swal from "sweetalert2";
import axios from "axios";

export const FormEditClient: React.FC<FormClientProps> = ({
    formValues,
    closeModal,
    onFormSubmit
}) => {

    const { auth, logout, setCurrentUser } = useAuth();

    const [formData, setFormData] = useState(formValues || {
        id: '0',
        name: '',
        rg: '',
        cpf: '',
        telefone: '',
        email: '',
        id_client_relationship: 0,
        address: '',
        number: '',
        zip_code: '',
        neighborhood: '',
        city: '',
        state: '',
        complement: '',
    });

    const [formErrors, setFormErrors] = useState<{ [key: string | number]: string | number }>({
        name: '',
        cpf: '',
        telefone: '',
        email: '',
        address: '',
        number: '',
        zip_code: '',
        neighborhood: '',
        city: '',
        state: '',
    });

    useEffect(() => {
        if (formValues) {
            setFormData({
                id: formValues.id,
                name: typeof formValues.name === 'string' ? formValues.name : '',
                rg: typeof formValues.rg === 'string' ? formValues.rg : '',
                cpf: typeof formValues.cpf === 'string' ? formValues.cpf : '',
                telefone: typeof formValues.telefone === 'string' ? formValues.telefone : '',
                email: typeof formValues.email === 'string' ? formValues.email : '',
                id_client_relationship: formValues.id_client_relationship || 0,
                address: typeof formValues.address === 'string' ? formValues.address : '',
                number: typeof formValues.number === 'string' ? formValues.number : '',
                zip_code: typeof formValues.zip_code === 'string' ? formValues.zip_code : '',
                neighborhood: typeof formValues.neighborhood === 'string' ? formValues.neighborhood : '',
                city: typeof formValues.city === 'string' ? formValues.city : '',
                state: typeof formValues.state === 'string' ? formValues.state : '',
                complement: typeof formValues.complement === 'string' ? formValues.complement : '',
            });
        }

    }, [formValues]);

    const handleChange = useCallback((e: any) => {

        const { name, value } = e.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }, []);

    function returnForm() {
        if (formData.id !== '0') {
            if (typeof onFormSubmit === 'function') {
                onFormSubmit(formData);
            }
        }
    }

    const handleCepBlur = async () => {

        if (!!formData.zip_code) {
            const cep = formData.zip_code.replace(/\D/g, '');

            if (!cep) {
                return;
            }

            try {
                const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

                if (response.data.erro) {
                    // CEP não encontrado
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Cep não encontrado'
                    });
                    setFormData({
                        ...formData,
                        zip_code: '',
                        address: '',
                        neighborhood: '',
                        city: '',
                        state: ''
                    });
                } else {
                    setFormData({
                        ...formData,
                        zip_code: response.data.cep || "",
                        address: response.data.logradouro || "",
                        neighborhood: response.data.bairro || "",
                        city: response.data.localidade || "",
                        state: response.data.uf || "",
                    });
                }

            } catch (error) {

                setFormData({
                    ...formData,
                    zip_code: '',
                    address: '',
                    neighborhood: '',
                    city: '',
                    state: '',
                });
            }
        }
    };

    // envia formulario
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {

            await SchemaClient.validate(formData, { abortEarly: false });
            setFormErrors({});

            await Swal.fire({
                icon: "info",
                title: "Atenção!",
                text: "Deseja salvar os dados do cliente?",
                showCancelButton: true,
                confirmButtonText: 'Sim, salvar!',
                cancelButtonText: 'Não',
            }).then((result) => {

                if (result.isConfirmed) {
                    saveForm();
                }
            });

        } catch (errors) {

            if (errors instanceof Yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                errors.inner.forEach((error) => {
                    validationErrors[error.path as string] = error.message
                });
                setFormErrors(validationErrors);
            }
        }
    };

    const saveForm = async () => {

        if (auth && auth.api_token) {
            const response = await editClientAPI(formData, auth);

            if (response.data.success) {
                Swal.fire({
                    icon: "success",
                    title: "Parabéns",
                    text: `Dados  atualizados com sucesso!`,
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                });

                if (response.data.data !== "0" && response.data.data !== null && response.data.data !== "") {
                    // let lastId = response.data.data.toString();  

                    // setFormData(prevState => ({
                    //     ...prevState,                
                    //     ['id']: lastId
                    // }));

                    returnForm();
                }

            } else if (response.data.status && response.data.status !== 403) {
                Swal.fire({
                    icon: "info",
                    title: "Atenção",
                    html: `Não foi possível salvar, verifique a conexao com internet, <br> ou fale com um administrador do sistema.`,
                    showCancelButton: false,
                    confirmButtonText: 'Entendi',
                });
            }
        }
    }

    return (
        <div className={`modal fade bg-dark bg-opacity-75 ${!closeModal ? "" : "show"}`} id="modalEditContract" tabIndex={-1} aria-modal="true" role="dialog" style={{ "display": !closeModal ? "none" : 'block' }}>
            <div className="modal-dialog modal-xl p-5 p-lg-10" id="">
                <div className="modal-content rounded-4">
                    <div className="modal-header flex-stack border-0 px-10 pt-5 pb-0" id="">
                        <div id="" className="position-relative z-index-1"></div>

                        <div className="btn btn-sm btn-icon btn-active-color-primary me-n2 position-relative z-index-1" onClick={closeModal}>
                            <KTSVG className="svg-icon svg-icon-2x" path="/media/icons/duotune/general/gen034.svg" />
                        </div>
                    </div>

                    <div className="modal-body pt-0 pb-5 px-15 mt-n5" id="">
                        <div className="container-fluid">
                            <div className="d-block">
                                <div className="tab-content">
                                    <div className="pt-5 tab-pane fade active show" role="tabpanel">
                                        <form onSubmit={handleSubmit}>
                                            {/* Contrato */}
                                            <div className="separator d-flex flex-center">
                                                <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">Dados do Cliente</span>
                                            </div>
                                            <div className="pe-12 me-n12 mt-15" >
                                                <div className="row gy-5">

                                                    {/*  */}
                                                    <div className="col-lg-3 fv-row fv-plugins-icon-container form-floating">
                                                        <label className='form-label fw-bold'></label>
                                                        <input
                                                            name="name"
                                                            placeholder="Nome"
                                                            className={`form-control ${formErrors.name ? ' is-invalid' : ''}`}
                                                            value={formData.name || ''}
                                                            onChange={handleChange}

                                                        />
                                                        <label className="ms-3" htmlFor="name">Nome</label>
                                                        {formErrors.name && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.name}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-3 fv-row fv-plugins-icon-container form-floating">

                                                        <InputMask
                                                            className={`form-control form-control-lg ${formErrors.cpf ? ' is-invalid' : ''}`} placeholder="Cpf"
                                                            mask="999.999.999-99"
                                                            name="cpf"
                                                            value={formData.cpf?.toString() || ''}
                                                            onChange={handleChange}
                                                        />
                                                        <label className="ms-3" htmlFor="cpf">Cpf</label>
                                                        {formErrors.cpf && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.cpf}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-3 fv-row fv-plugins-icon-container form-floating">
                                                        <input
                                                            name="rg"
                                                            placeholder="RG"
                                                            className={`form-control ${formErrors.rg ? ' is-invalid' : ''}`}
                                                            value={formData.rg || ''}
                                                            onChange={handleChange}

                                                        />
                                                        <label className="ms-3" htmlFor="rg">RG</label>
                                                        {formErrors.rg && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.rg}</div>
                                                        )}
                                                    </div>


                                                    <div className="col-lg-3 fv-row fv-plugins-icon-container form-floating">

                                                        <InputMask
                                                            className={`form-control ${formErrors.telefone ? ' is-invalid' : ''}`}
                                                            placeholder="Telefone"
                                                            id="telefone"
                                                            mask="(99) 99999-9999"
                                                            name="telefone"
                                                            value={formData.telefone?.toString() || ''}
                                                            onChange={handleChange}
                                                        />
                                                        <label className="ms-3" htmlFor="telefone">Telefone</label>
                                                        {formErrors.telefone && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.telefone}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-3 fv-row fv-plugins-icon-container form-floating">
                                                        <input
                                                            name="email"
                                                            placeholder="E-mail"
                                                            className={`form-control ${formErrors.email ? ' is-invalid' : ''}`}
                                                            value={formData.email || ''}
                                                            onChange={handleChange}

                                                        />
                                                        <label className="ms-3" htmlFor="email">E-mail</label>
                                                        {formErrors.email && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.email}</div>
                                                        )}
                                                    </div>


                                                    <div className="col-lg-2 fv-row fv-plugins-icon-container form-floating">
                                                        <InputMask
                                                            className={`form-control ${formErrors.zip_code ? ' is-invalid' : ''}`}
                                                            placeholder="Cep"
                                                            id="zip_code"
                                                            mask="99999-999"
                                                            name="zip_code"
                                                            value={formData.zip_code?.toString() || ''}
                                                            // unmask
                                                            onBlur={handleCepBlur}
                                                            onChange={handleChange}
                                                        />
                                                        <label className="ms-3" htmlFor="cep">Cep</label>
                                                        {formErrors.zip_code && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.zip_code}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-4  fv-row fv-plugins-icon-container form-floating">
                                                        <input
                                                            name="address"
                                                            placeholder="Endereço"
                                                            className={`form-control ${formErrors.address ? ' is-invalid' : ''}`}
                                                            value={formData.address || ''}
                                                            onChange={handleChange}

                                                        />
                                                        <label className="ms-3" htmlFor="address">Endereço</label>
                                                        {formErrors.address && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.address}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-3 fv-row fv-plugins-icon-container form-floating">
                                                        <input
                                                            name="neighborhood"
                                                            placeholder="Bairro"
                                                            className={`form-control ${formErrors.neighborhood ? ' is-invalid' : ''}`}
                                                            value={formData.neighborhood || ''}
                                                            onChange={handleChange}

                                                        />
                                                        <label className="ms-3" htmlFor="neighborhood">Bairro</label>
                                                        {formErrors.neighborhood && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.neighborhood}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-3 fv-row fv-plugins-icon-container form-floating">
                                                        <input
                                                            name="number"
                                                            placeholder="Número"
                                                            className={`form-control ${formErrors.number ? ' is-invalid' : ''}`}
                                                            value={formData.number || ''}
                                                            onChange={handleChange}

                                                        />
                                                        <label className="ms-3" htmlFor="Número">Número</label>
                                                        {formErrors.number && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.number}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-3 fv-row fv-plugins-icon-container form-floating">
                                                        <input
                                                            name="city"
                                                            placeholder="Cidade"
                                                            className={`form-control ${formErrors.city ? ' is-invalid' : ''}`}
                                                            value={formData.city || ''}
                                                            onChange={handleChange}

                                                        />
                                                        <label className="ms-3" htmlFor="city">Cidade</label>
                                                        {formErrors.city && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.city}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-2 fv-row fv-plugins-icon-container form-floating">
                                                        <input
                                                            name="state"
                                                            placeholder="Estado"
                                                            className={`form-control ${formErrors.state ? ' is-invalid' : ''}`}
                                                            value={formData.state || ''}
                                                            onChange={handleChange}

                                                        />
                                                        <label className="ms-3" htmlFor="state">Estado</label>
                                                        {formErrors.state && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.state}</div>
                                                        )}
                                                    </div>

                                                    <div className="col-lg-4 fv-row fv-plugins-icon-container form-floating">
                                                        <input
                                                            name="complement"
                                                            placeholder="Complemento"
                                                            className={`form-control ${formErrors.complement ? ' is-invalid' : ''}`}
                                                            value={formData.complement || ''}
                                                            onChange={handleChange}

                                                        />
                                                        <label className="ms-3" htmlFor="complement">Complemento</label>
                                                        {formErrors.complement && (
                                                            <div className="fv-plugins-message-container invalid-feedback">{formErrors.complement}</div>
                                                        )}
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="modal-footer border-0 mt-5">
                                                <button type="button" className="btn btn-light" onClick={closeModal}>Fechar</button>
                                                <button type="submit" className="btn btn-success me-4">
                                                    {/* <button type="submit" className="btn btn-success me-4" onClick={handlerSaveForm}> */}
                                                    Salvar
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}