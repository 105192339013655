import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    setContractFilter,
    setPaymentsFilter,
    setParcelFilter,
    setProductFilter,
    setProductPlanFilter,
    setUnitFilter,
    setClientFilter,
    setDashboardFilter,
    setCorretorFilter
} from './../../app/redux/filter/filterSlice';
import Dashboard from '@uppy/dashboard';

type FilterCategory = 'contract' | 'payment' | 'parcel' | 'product' | 'product_plan' | 'unit' | 'client' | 'dashboard' | 'corretor';

export const useFormFilter = (category: FilterCategory) => {
    const dispatch = useDispatch();

    // Initialize with proper structure based on the category
    const getInitialState = () => {
        switch (category) {
            case 'contract':
                return {
                    contract: {
                        id_client: '',
                        id_contract: '',
                        id_unit: '',
                        status: '',
                    }
                };
            case 'payment':
                return {
                    payment: {
                        type_payment: '',
                        id_contract: '',
                        id_client: '',
                        start_due_date: '',
                        end_due_date: '',
                        start_date_contract: '',
                        end_date_contract: '',
                        start_payment_date: '',
                        end_payment_date: '',
                        value: '',
                        status_contract: '1',
                        form_payment: '',
                        date_payment: '',
                        assessment: '',
                        boleto: '',
                        or_number: '',
                        possui_nota: ''
                    }
                };
            case 'parcel':
                return {
                    parcel: {
                        id_client: "",
                        status: " ",
                        start_due_date: "",
                        end_due_date: "",
                        start_date_payment: "",
                        end_date_payment: "",
                        type_parcel: "",
                        form_payment: "",
                        boleto: "",
                        nota_fiscal: ""
                    }

                };

            case 'dashboard':
                return {
                    dashboard: {
                        id_product: '',
                        start_date: '',
                        end_date: '',
                        status: '',
                    }
                };
                break;
            // Add other categories as needed
            default:
            case 'dashboard':
                return {
                    dashboard: {
                        id_product: '',
                        start_date: '',
                        end_date: '',
                        status: '',
                    }
                };
        }
    };

    const [formData, setFormData] = useState(getInitialState());

    // Map categories to their respective actions
    const actionMap = {
        contract: setContractFilter,
        payment: setPaymentsFilter,
        parcel: setParcelFilter,
        product: setProductFilter,
        product_plan: setProductPlanFilter,
        unit: setUnitFilter,
        client: setClientFilter,
        corretor: setCorretorFilter,
        dashboard: setDashboardFilter
    };

    // useFormFilter.ts
    const handleChange = (field: string, value: any) => {
        setFormData((prevState: any) => {
            const updateObj: any = { ...prevState };

            if (category === 'payment') {
                updateObj.payment = {
                    ...prevState.payment,
                    [field]: value?.value || value
                };
            } else if (category === 'contract') {
                updateObj.contract = {
                    ...prevState.contract,
                    [field]: value?.value || value
                };
            } else if (category === 'parcel') {
                updateObj.parcel = {
                    ...prevState.parcel,
                    [field]: value?.value || value
                };
            } else if (category === 'dashboard') {
                updateObj.dashboard = {
                    ...prevState.dashboard,
                    [field]: value?.value || value
                };
            }
            //  else if (category === 'corretor') {
            //     updateObj.corretor = {
            //         ...prevState.corretor,
            //         [field]: value?.value || value
            //     };
            // }

            return updateObj;
        });
    };

    const handleDateRange = (event: Event, picker: any, field: string) => {
        // const start_date = picker.start_date.tz('America/Sao_Paulo').toDate();
        const start_date = picker.startDate;
        const end_date = picker.endDate;

        setFormData((prevState: any) => {
            const updateObj: any = { ...prevState };

            if (category === 'payment') {
                updateObj.payment = {
                    ...prevState.payment,
                    [`start_${field}`]: start_date.format('YYYY-MM-DD'),
                    [`end_${field}`]: end_date.format('YYYY-MM-DD'),
                };

            } else if (category === 'contract') {
                updateObj.contract = {
                    ...prevState.contract,
                    [`start_${field}`]: start_date.format('YYYY-MM-DD'),
                    [`end_${field}`]: end_date.format('YYYY-MM-DD'),
                };
            } else if (category === 'parcel') {
                updateObj.parcel = {
                    ...prevState.parcel,
                    [`start_${field}`]: start_date.format('YYYY-MM-DD'),
                    [`end_${field}`]: end_date.format('YYYY-MM-DD'),
                };
            }

            return updateObj;
        });
    }

    // Handle form submission
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // Dispatch the appropriate action based on the category
        if (category === 'contract' && formData.contract) {
            dispatch(actionMap[category](formData.contract));
        } else if (category === 'payment' && formData.payment) {
            dispatch(actionMap[category](formData.payment));
        } else if (category === 'parcel' && formData.parcel) {
            dispatch(actionMap[category](formData.parcel));
        } else if (category === 'dashboard' && formData.dashboard) {
            dispatch(actionMap[category](formData.dashboard));
        }
        //  else if (category === 'corretor' && formData.corretor) {
        //     dispatch(actionMap[category](formData.corretor));
        // }
        else {
            // Handle other categories similarly
            // dispatch(actionMap[category](formData));
        }
    };

    return {
        formData,
        setFormData,
        handleChange,
        handleDateRange,
        handleSubmit
    };
};